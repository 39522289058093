var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isHost || _vm.currentPlay
    ? _c(
        "PlayLayout",
        {
          staticClass: "telephone-pictionary-draw",
          scopedSlots: _vm._u(
            [
              {
                key: "left",
                fn: function () {
                  return [
                    _c("ResizableText", {
                      staticClass: "telephone-pictionary__text",
                      attrs: { message: _vm.message, transition: false },
                    }),
                    _c(
                      "RtbButton",
                      {
                        staticClass: "font-bold",
                        attrs: {
                          disabled:
                            _vm.working ||
                            _vm.isImageSubmitted ||
                            _vm.isHost ||
                            !_vm.isScribe,
                        },
                        on: { click: _vm.onSubmit },
                      },
                      [
                        _vm.working
                          ? _c("v-progress-circular", {
                              attrs: {
                                indeterminate: "",
                                color: "#fff",
                                size: "24",
                              },
                            })
                          : _vm._e(),
                        _vm.isImageSubmitted && !_vm.working
                          ? [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("check"),
                              ]),
                              _vm._v(" Submitted "),
                            ]
                          : [_vm._v(" Submit ")],
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "right",
                fn: function () {
                  return [
                    _c("DrawingPicker", { attrs: { sizeResetable: false } }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            1181085530
          ),
        },
        [
          _c("Drawable", {
            attrs: {
              bgColor: "#fff",
              width: 510,
              height: 270,
              lineColorRgb: _vm.lineColorRgb,
              lineWidth: _vm.lineWidth,
              pen: _vm.pen,
              alpha: _vm.alpha,
              points: _vm.points,
              canDraw: !_vm.isImageSubmitted && !_vm.isHost,
            },
            on: { mouseUp: _vm.onMouseUp },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }