var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "telephone-pictionary-result",
      attrs: { "fill-height": "", "fill-width": "" },
    },
    [
      _c(
        "transition-group",
        {
          staticClass: "telephone-pictionary__carousel",
          attrs: { tag: "div", name: "telephone-pictionary" },
        },
        _vm._l(_vm.activeSlides, function (slide) {
          return _c(
            "div",
            { key: slide.index, staticClass: "telephone-pictionary__slide" },
            [
              slide.title
                ? _c("div", { staticClass: "telephone-pictionary__tag" }, [
                    _vm._v(" " + _vm._s(slide.title) + " "),
                  ])
                : _vm._e(),
              slide.text || slide.url
                ? _c("MissionCard", [
                    slide.text
                      ? _c("h1", [_vm._v(_vm._s(slide.text))])
                      : _vm._e(),
                    slide.url
                      ? _c("img", {
                          staticClass: "telephone-pictionary-result__picture",
                          attrs: { src: slide.url, alt: "picture" },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _vm.isHost
        ? _c(
            "div",
            { staticClass: "telephone-pictionary__carousel-dots" },
            _vm._l(_vm.slides, function (dot, index) {
              return _c("div", {
                key: dot.index,
                staticClass: "telephone-pictionary__carousel-dot",
                class: {
                  "telephone-pictionary__carousel-dot--active":
                    index === _vm.slideShowIndex,
                },
              })
            }),
            0
          )
        : _vm._e(),
      _vm.isHost
        ? _c(
            "div",
            { staticClass: "telephone-pictionary__controls" },
            [
              _c("SvgIcon", {
                staticClass: "telephone-pictionary__arrow",
                class: {
                  "telephone-pictionary__arrow--disabled":
                    _vm.slideShowIndex === 0,
                },
                attrs: { name: "arrow-right" },
                on: {
                  click: function ($event) {
                    return _vm.setSlideindex(-1)
                  },
                },
              }),
              _c("SvgIcon", {
                staticClass: "telephone-pictionary__arrow",
                class: {
                  "telephone-pictionary__arrow--disabled":
                    _vm.slideShowIndex === _vm.slides.length - 2,
                },
                attrs: { name: "arrow-left" },
                on: {
                  click: function ($event) {
                    return _vm.setSlideindex(1)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }