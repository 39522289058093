






























import Vue from "vue"
import { computed, ref } from "@vue/composition-api"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"
import TextField from "@/components/GroupTeams/Common/Games/TextField.vue"
import { createPlay } from "@/mixins/Game.js"
import useStore from "@/use/useStore"
import useDBText from "@/use/useDBText"

import useTelephonePictionary from "./useTelephonePictionary"
import _ from "lodash"

export default Vue.extend({
  name: "TelephonePictionaryForm",
  components: {
    PlayLayout,
    ResizableText,
    TextField
  },

  setup() {
    const { store } = useStore()

    const {
      currentPlay,
      viewer,
      mission,
      isScribe,
      missionStep,
      currentAnswer,
      sessionId,
      activityId,
      prevAnswer,
      MAX_STEP,
      isLastStep
    } = useTelephonePictionary()

    const { text, sessionRef } = useDBText(activityId, sessionId)

    const game = computed(() => store.getters.game)

    const instructions = computed(() => {
      if (missionStep.value === 0) {
        return mission.value.instructions
      }

      if (isLastStep.value) {
        return "And now for the results"
      }
      return "Describe the picture"
    })

    const isSubmitted = computed(() => {
      return !!currentAnswer?.value?.text
    })

    function onInput(text) {
      sessionRef.value.update({ text })
    }

    function onSubmit(data) {
      const answer = {
        text: data,
        userID: viewer.value.id,
        teamID: viewer.value.teamID
      }

      let play

      if (missionStep.value) {
        play = currentPlay.value
      } else {
        play = createPlay({
          user: viewer.value,
          mission: mission.value,
          answer: [],
          game: game.value,
          teamID: viewer.value.teamID
        })
        play.correct = false
        play.show = false
      }

      play.answer.push(answer)

      store.dispatch("addPlay", play)
    }

    const placeholder = computed(() => {
      if (currentAnswer?.value?.text) {
        return currentAnswer?.value?.text
      }

      if (!isScribe.value) {
        return "Waiting for scribe..."
      }

      return "Type here ..."
    })

    return {
      text,
      isLastStep,
      onSubmit,
      onInput,
      prevAnswer,
      isSubmitted,
      placeholder,
      currentAnswer,
      instructions
    }
  }
})
