






























































import Vue from "vue"
import { mapGetters } from "vuex"
import useStore from "@/use/useStore"
import User from "@shared/User"

import ModeSlideTransition from "@/components/GroupTeams/Common/GameModes/ModeSlideTransition.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"

import useTelephonePictionary from "./useTelephonePictionary"
import { computed, ref } from "@vue/composition-api"

export default Vue.extend({
  name: "TelephonePictionaryResult",
  components: {
    MissionCard,
    ResizableText,
    ModeSlideTransition
  },
  setup() {
    const { store } = useStore()
    const teams = computed(() => store.getters.chats)
    const { missionPlays, scribesById, isTeamPlay } = useTelephonePictionary()
    const transitionName = ref("telestration-fade")
    const slideShowIndex = computed(
      () => store.getters.gameStatus?.slideShowIndex || 0
    )

    function getTeamName(teamID) {
      return teams.value[teamID]?.name
    }

    function getUserName(userID) {
      return User.getShortenedName(scribesById.value[userID])
    }

    function getTitleByKey(id) {
      return isTeamPlay.value ? getTeamName(id) : getUserName(id)
    }

    const slides = computed(() => {
      return missionPlays.value
        .reduce((acc, play, index, arr) => {
          const answers = play.answer.reduce((res, item) => {
            const { text, url } = item

            let titleKey = isTeamPlay.value ? "teamID" : "userID"

            const textTitle = getTitleByKey(item[titleKey])

            res.push({
              ...(text && { text }),
              ...(textTitle && { title: textTitle }),
              url
            })

            return res
          }, [])

          if (arr.length - 1 !== index) {
            answers.push({})
          }

          Array.prototype.push.apply(acc, answers)
          return acc
        }, [])
        .map((item, index) => ({ ...item, index }))
    })

    const activeSlides = computed(() => {
      const slidesPerScreen = 2
      const slidesIndex = slideShowIndex.value
      return slides.value.slice(slidesIndex, slidesPerScreen + slidesIndex)
    })

    function setSlideindex(dir) {
      const len = slides.value.length
      const nextIndex = (slideShowIndex.value + (dir % len) + len) % len

      store.dispatch("updateGameStatusAny", {
        slideShowIndex: nextIndex
      })
    }

    return {
      setSlideindex,
      teams,
      slides,
      activeSlides,
      transitionName,
      slideShowIndex
    }
  },

  computed: {
    ...mapGetters("auth", ["isHost"])
  }
})
