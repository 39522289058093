var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "telephone-pictionary" },
    [
      _c(
        "ModeSlideTransition",
        [
          _vm.mode === _vm.modeTypes.Play
            ? [
                _vm.isWriting ? _c("TelephonePictionaryForm") : _vm._e(),
                _vm.isDrawing ? _c("TelephonePictionaryDraw") : _vm._e(),
              ]
            : _vm._e(),
          _vm.mode === _vm.modeTypes.Results
            ? _c("TelephonePictionaryResult")
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }