import { db } from "@/firebase"
import { ref, computed, watch, onBeforeUnmount } from "@vue/composition-api"

export default function useDBText(textId, sessionId) {
  const text = ref("")

  const sessionRef = computed(() =>
    db.auxiliary().ref(`texts/${textId.value}/sessions/${sessionId.value}`)
  )

  function onSnapshot(snapshot) {
    text.value = snapshot.val()?.text || ""
  }

  watch(
    sessionRef,
    (newValue, oldValue) => {
      oldValue?.off("value", onSnapshot)
      newValue.on("value", onSnapshot)
    },
    { immediate: true }
  )

  onBeforeUnmount(() => sessionRef.value.off("value", onSnapshot))

  return {
    sessionRef,
    text
  }
}
