var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PlayLayout",
    { staticClass: "telephone-pictionary-form" },
    [
      _c("ResizableText", {
        staticClass: "telephone-pictionary__text",
        attrs: { message: _vm.instructions, transition: false },
      }),
      _vm.prevAnswer && !_vm.isLastStep
        ? _c("img", {
            staticClass: "telephone-pictionary-form__picture",
            attrs: { src: _vm.prevAnswer.url, alt: "picture" },
          })
        : _vm._e(),
      !_vm.isLastStep
        ? _c("TextField", {
            staticClass: "telephone-pictionary-form__field",
            attrs: {
              size: "sm",
              value: _vm.text,
              placeholder: _vm.placeholder,
              show: "show",
              status: "active",
              buttonText: _vm.isSubmitted ? "Submitted" : "Submit",
              disabled: _vm.isSubmitted,
              maxTextLength: 120,
            },
            on: { input: _vm.onInput, onSubmit: _vm.onSubmit },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }