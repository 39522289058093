














































import Vue from "vue"
import _ from "lodash"
import { mapState, mapGetters } from "vuex"
import { computed, ref } from "@vue/composition-api"

import { RtbButton } from "@/components/ui"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import Drawable from "@/components/Drawable/Drawable.vue"
import DrawingPicker from "@/components/GroupTeams/Common/GameModes/DrawingPicker.vue"
import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"
import useDrawPoints from "@/use/useDrawPoints"
import { submitDrawingImage } from "@/services/api.service"

import useStore from "@/use/useStore"
import useTelephonePictionary from "@/components/GroupTeams/Common/Games/TelephonePictionary/useTelephonePictionary"

export default Vue.extend({
  name: "TelephonePictionaryDraw",

  components: {
    Drawable,
    RtbButton,
    PlayLayout,
    ResizableText,
    DrawingPicker
  },

  setup() {
    const { store } = useStore()

    const working = ref(false)

    const {
      currentPlay,
      viewer,
      prevAnswer,
      currentAnswer,
      isScribe,
      activityId,
      sessionId
    } = useTelephonePictionary()

    const token = computed(() => store.getters["auth/token"])

    const { points, sessionRef } = useDrawPoints(activityId, sessionId)

    const isImageSubmitted = computed(() => !!currentAnswer.value?.url)

    const message = computed(() => "Draw - " + (prevAnswer.value?.text || ""))

    function onMouseUp({ points, lineColor, lineWidth }) {
      sessionRef.value.push({
        data: JSON.stringify({ points, lineColor, lineWidth })
      })
    }

    async function onSubmit() {
      working.value = true
      const { downloadURL } = await submitDrawingImage({
        drawingId: activityId.value,
        sessionId: sessionId.value,
        token: token.value
      })

      const play = currentPlay.value

      play.answer.push({
        userID: viewer.value.id,
        teamID: viewer.value.teamID,
        url: downloadURL
      })

      store.dispatch("addPlay", play)

      working.value = false
    }

    return {
      working,
      currentPlay,
      message,
      points,
      sessionId,
      isScribe,
      onSubmit,
      onMouseUp,
      isImageSubmitted
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState("drawing", ["lineColorRgb", "lineWidth", "alpha", "pen"]),
    ...mapGetters("auth", ["isHost"])
  },

  watch: {},

  methods: {}
})
