












import Vue from "vue"
import { mapGetters } from "vuex"

import { Mode } from "@shared/enums"

import useStore from "@/use/useStore"

import TelephonePictionaryForm from "@/components/GroupTeams/Common/Games/TelephonePictionary/TelephonePictionaryForm.vue"
import TelephonePictionaryDraw from "@/components/GroupTeams/Common/Games/TelephonePictionary/TelephonePictionaryDraw.vue"
import TelephonePictionaryResult from "@/components/GroupTeams/Common/Games/TelephonePictionary/TelephonePictionaryResult.vue"
import ModeSlideTransition from "@/components/GroupTeams/Common/GameModes/ModeSlideTransition.vue"
import useTelephonePictionary from "./useTelephonePictionary"

export default Vue.extend({
  name: "TelephonePictionary",

  components: {
    TelephonePictionaryForm,
    TelephonePictionaryDraw,
    ModeSlideTransition,
    TelephonePictionaryResult
  },

  setup() {
    const { store } = useStore()
    const { isWriting, isDrawing, mission, isHost } = useTelephonePictionary()

    function setMissionStep(step) {
      store.dispatch("updateGameStatusAny", {
        telephonePictionary: { [mission.value.id]: step }
      })
    }

    return {
      isWriting,
      isDrawing,
      isHost
    }
  },

  computed: {
    ...mapGetters({ mode: "getCurrentMode" }),
    modeTypes() {
      return Mode
    }
  }
})
